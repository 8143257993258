const header = document.querySelector('#header');
const sideBar = document.querySelector('#sidebar');
const sideBarItems = sideBar.querySelectorAll('.menu-item');
const backToTop = document.querySelector('#back-to-top');
const darkbgItems = document.querySelectorAll('[data-bg="dark"]');

sideBarItems.forEach((item) => {
  const ink = item.querySelector('a');
  const title = ink.getAttribute('title');
  const icon = document.createElement('i');
  const text = document.createElement('span');
  text.textContent = ink.textContent;
  ink.textContent = '';
  icon.classList.add(`icon-${title}`);
  ink.append(text);
  ink.append(icon);
});

const hideHeader = () => {
  header.classList.add('header__top--hide');
};

const showHeader = () => {
  header.classList.remove('header__top--hide');
};

const checkDarkBg = () => {
  let isAboveDarkSection = false;

  darkbgItems.forEach((section) => {
    const sectionRect = section.getBoundingClientRect();
    const sidebarRect = sideBar.getBoundingClientRect();

    if (
      sidebarRect.top <= sectionRect.bottom &&
      sidebarRect.bottom >= sectionRect.top
    )
      isAboveDarkSection = true;
  });

  isAboveDarkSection
    ? sideBar.classList.add('header-sidebar--light')
    : sideBar.classList.remove('header-sidebar--light');
};

let lastScrollTop = 0;
window.addEventListener('scroll', () => {
  checkDarkBg();

  const st = window.pageYOffset;
  st >= 400
    ? (hideHeader(),
      st < lastScrollTop && header.classList.remove('header__top--hide'),
      (lastScrollTop = st <= 0 ? 0 : st))
    : showHeader();
});

backToTop.addEventListener('click', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

const topBar = new Swiper('#topbar .swiper', {
  slidesPerView: 1,
  loop: true,

  autoplay: {
    delay: 5000,
  },
});

const testimoniesSwiper = new Swiper('#testimonies-swiper', {
  slidesPerView: 'auto',
  centeredSlides: true,
  initialSlide: 1,
  slideToClickedSlide: true,
  autoplay: {
    disableOnInteraction: false,
    delay: 5000,
  },

  breakpoints: {
    1: {
      autoplay: false,
    },
    992: {
      autoplay: {
        disableOnInteraction: false,
        delay: 5000,
      },
    },
  },
});

const testimoniesPlayer = document.querySelectorAll('.student-card > .player');
testimoniesPlayer.forEach((player) => {
  const plyr = new Plyr(player);
});

const videosYoutubeContainer = document.querySelectorAll(
  '.wp-block-embed__wrapper'
);

videosYoutubeContainer.forEach((item) => {
  item.classList.add('plyr__video-embed');
  const itempplyr = new Plyr(item, {
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'fullscreen',
    ],
  });
});
